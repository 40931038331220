import http from "./axios_init";
export default {
  /**
   * 获取所有
   * @returns
   */
  get_all() {
    return http.get("admin/v1/udfArea");
  },

    /**
   * 获取所有
   * @returns
   */
    get_select() {
      return http.get("admin/v1/udfArea/select");
    },
};
