<!--
 * @Description: 自定义区域
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-14 20:38:53
-->
<template>
  <el-card class="box query">
    <div class="actions">
      <el-button
        type="primary"
        size="small"
        round
        icon="plus"
        @click="onEdit"
        v-if="is_can_edit"
        >新增</el-button
      >
    </div>
  </el-card>
  <el-card class="box data">
    <el-table :data="data_list" v-loading="loading" border stripe>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="序号" prop="sequence" width="100"></el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-area
    :item="current_item"
    @success="loadData"
    @closed="current_item = null"
  ></edit-area>
</template>

<script>
import udfarea_api from "@/http/udfarea_api";
import editArea from "./edit_udfarea.vue";

export default {
  components: {
    editArea,
  },
  data() {
    return {
      loading: true,
      data_list: [],
      current_item: null,
    };
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/udfArea/edit");
    this.is_can_del = this.$power("admin/v1/udfArea/del");
    this.loadData();
  },
  methods: {
    /**
     *
     */
    loadData() {
      udfarea_api.get_all().then((res) => {
        if (res.code == 0) {
          this.data_list = res.data;
          this.loading = false;
        }
      });
    },
    /**
     *
     */
    onEdit(item) {
      this.current_item = Object.assign({}, item);
    },
         /**
     * 删除
     */
     onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/udfArea/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg); 
          this.loadData();
        }
      });
    },
  },
};
</script>

<style scoped></style>
